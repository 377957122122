import { ReferenceItem } from "@/services/types";
import SearchProfile from "./SearchProfile";
import { ContactIndustry } from "@/entity/Industry";
import Contact, { Address, CreateContact } from "./Contact";

export const countryList: ReferenceItem[] = [
  {
    id: 1,
    name: "Switzerland",
  },
  {
    id: 2,
    name: "Germany",
  },
];

export enum SalutationForm {
  MR = "mr",
  MRS = "mrs",
  FAMILY = "family",
  MR_AND_MRS = "mrAndMrs",
}

export enum ContactTypeId {
  company = 1,
  contact = 2,
}

export interface CreateCompany {
  id: number | null;
  firstName: string;
  name: string;
  contactId: null | number;
  ownerContact: null | Partial<CreateContact>;
  url: string;
  email: string;
  address: Address | undefined;
  phoneMobile: string;
  phoneFixed: string;
  isExternal: boolean;
  subBranchIds: number[];
  branches: ContactIndustry[];
  projectReferenceIds: number[];
  regionId: null | number;
  buyerTypeId: null | number;
  employees: number | null;
  companyTypeId: number | null;
  fullTimeEquivalent: string | null;
  addressId: null | number | undefined;
  remarks: string;
}

export function initialCompanyData(): CreateCompany {
  return {
    id: null,
    firstName: "",
    name: "",
    contactId: null,
    ownerContact: null,
    url: "",
    email: "",
    phoneMobile: "",
    phoneFixed: "",
    isExternal: false,
    address: {
      address: "",
      postcode: "",
      city: "",
      country: 0,
    },
    branches: [],
    subBranchIds: [],
    projectReferenceIds: [],
    regionId: null,
    buyerTypeId: null,
    employees: null,
    companyTypeId: null,
    fullTimeEquivalent: null,
    addressId: null,
    remarks: "",
  };
}

export function contactToCreateCompany(company: Company): CreateCompany {
  return {
    id: company.id,
    firstName: company.name,
    name: company.name,
    contactId: company.contactId,
    url: company.url,
    email: company.email,
    phoneMobile: company.phoneMobile,
    phoneFixed: company.phoneFixed,
    isExternal: company.isExternal,
    address: company.address,
    subBranchIds: company.subBranches?.map((subBranch) => subBranch.id) ?? [],
    branches: company.contactBranches ?? [],
    ownerContact: null,
    projectReferenceIds: company.projectReferenceIds ?? [],
    regionId: company.regionId,
    buyerTypeId: company.buyerTypeId,
    employees: company.employees,
    companyTypeId: company.companyTypeId,
    fullTimeEquivalent: company.fullTimeEquivalent,
    addressId: company.addressId,
    remarks: company.remarks,
  };
}

export default interface Company {
  id: number | null;
  name: string;
  url: string;
  email: string;
  contact: Contact;
  contacts: Contact[];
  address?: Address;
  firstName: string;
  fullName?: string;
  lastName?: string;
  title: string;
  country: ReferenceItem | null;
  contactBranches: ContactIndustry[] | null;
  subBranches: ReferenceItem[] | null;
  phoneMobile: string;
  phoneFixed: string;
  remarks: string;
  contactTypeId: number;
  nr: string;
  salutationForm: SalutationForm | "";
  ndaSigned: boolean;
  ndaSent: boolean;
  ndaApproved: boolean;
  hasSearchProfile: boolean | null;
  hasProject: boolean | null;
  isExternal: boolean;
  isBlocked: boolean;
  isDisabled: boolean;
  contactId: number | null;
  owner: Contact | null;
  regionId: number | null;
  region: ReferenceItem | null;
  searchProfile: SearchProfile;
  buyerTypeId: number | null;
  buyerType: ReferenceItem | null;
  projectReferenceIds: number[];
  companies: null | Contact[];
  employees: number | null;
  companyTypeId: number | null;
  fullTimeEquivalent: string | null;
  companyType: string | null;
  addressId?: number | null;
}

export const getCompanyFullName = (company: Company | null): string => {
  if (!company) {
    return "";
  }

  return company.name;
};
